/*
 * @Auth: linjituan
 * @Date: 2022-03-24 19:27:02
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-24 19:28:25
 */
import request from '@/utils/request'
const baseURL = '/rc-api'

// 查询操作日志列表
export function list (query) {
  return request({
    url: '/internal/recyclable/operlog/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 删除操作日志
export function delOperlog (operId) {
  return request({
    url: '/internal/recyclable/operlog/' + operId,
    baseURL,
    method: 'delete'
  })
}

// 清空操作日志
export function cleanOperlog () {
  return request({
    url: '/internal/recyclable/operlog/clean',
    baseURL,
    method: 'delete'
  })
}

// 导出操作日志
export function exportOperlog (query) {
  return request({
    url: '/internal/recyclable/operlog/export',
    baseURL,
    method: 'get',
    params: query
  })
}
